var menu =
    [
        {
            title: 'Newsletters',
            route: 'dashboard', 
            icon: 'MailIcon',
        }
    ]

export default menu; 
