var menu =
    [
        {
            title: 'Layouts',
            route: 'mail-layout', 
            icon: 'CodesandboxIcon',
        }
    ]

export default menu;
